import React from "react";
import { log, validateEmail } from "../helpers/helper";
import { getPatientWithEmail } from "../helpers/pthelper";
import ReCAPTCHA from "react-google-recaptcha";
import { Recaptcha_site_key } from "../constants";



class ForgotController extends React.Component {
  constructor(props) {
    super();
    this.item = window.localStorage.getItem("twilio-flex-cf");
    log(this.item, 'item');
    window.localStorage.removeItem("twilio-flex-cf");
    log(this.item, 'after removing')
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      dob: "",
      showEmailError: false,
      scrolled_field:"",
      empty_fields: "",
      empty_fields_err: false,
      study_number : "",
      isLoading : "",
      isFound: "",
      recaptcha_response : "",
      empty: false
    };
  }

  handleChange = (e) => {
    //log(e.target.name,e.target.value , 'checking inputs');
    this.setState({ [e.target.name]: e.target.value }, () => {

    });
  };

  onRecaptchaChange = (value) =>{
    log(value,'rv')
    this.setState({ recaptcha_response : value})
  }

  sendInfo = () => {
    this.checkInputs();
  };

  scrollToField = (field) => {
    if(field && field.length > 0){
      const fieldDiv = document.getElementById(field);
      if (fieldDiv) {
        fieldDiv.scrollIntoView({ behavior: 'smooth',  block: 'start' });
        this.setState({scrolled_field: field})
      }
    }
  };

  onSubmit = () => {
    let {
      firstname,
      lastname,
      email,
      dob,
      recaptcha_response,
    } = this.state
    let email_err = this.state.showEmailError;
    let checkEmptyValues = {
      "First Name": firstname,
      "Last Name": lastname,
      "Date of Birth": dob,
      "Email": email,
      //"ReCaptcha" : recaptcha_response,
    }
    let e_values = []
    let check_keys = Object.keys(checkEmptyValues)
    let check = check_keys.map(item=>{
      let filter_empty = checkEmptyValues[item];
      if(filter_empty === "" || null || undefined){
        return e_values.push(item)
      }
    })
    if( validateEmail(this.state.email) == false){
      alert("Please enter valid email address")
    } 
    if (e_values && e_values.length > 0) {
      log(`${check},${e_values}, "check"`);
      this.setState({ empty_fields: e_values }, () => {
        this.setState({ empty_fields_err: true }, () => {
          this.scrollToField(e_values[0])
        })
      })
      //alert(`please enter all the below required values \n ${e_values.join(',')}`);
    } else {
      this.setState({isLoading : false})
      let input_body = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        dob: dob,
        study: "",
        recaptcha_response: recaptcha_response
      }
      getPatientWithEmail(input_body).then(pt=>{
        log(pt,'pt found');
        this.setState({isFound : true, isLoading: true})
      }).catch(errPt=>{
        log(errPt,'err pt')
        this.setState({isFound : false, isLoading : true})
      })
    }
  }


  componentDidMount() {
    
  }

  render() {
    let { firstname, lastname, email, dob, showEmailError, isFound, empty, empty_fields, empty_fields_err, recaptcha_response, scrolled_field , isLoading} = this.state
    return (
      <div style={{backgroundColor : "#c9d6d9"}} className="container my-0 py-2">
        <div className="d-flex align-items-center justify-content-center">
          <div className="col-6">
            <h6>To Retrieve User ID, please enter all the mandatory fields below.</h6>
            {empty_fields_err === true && empty_fields.length > 0 ?
              <div style={{ border: '2px solid red', borderRadius: '10px' }} className="d-flex align-items-center justify-content-center my-3">
                <div className="container m-3">
                  <h6>{`Please enter all the below required values`}</h6>
                  <p className="mb-0">{`${empty_fields.join(" " + " " + "," + " " + " ")}`}</p>
                </div>
              </div>
              : null}
            <div className="px-2">
              <div
                style={{ height: 55 }}
                id="First Name"
                className="search_input form-group"
              >
                <p className="mb-0 pl-2">
                  {"First Name"}
                  <span style={{ color: "red", fontSize: 13 }}>*</span>
                </p>
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    height: 20,
                  }}
                  type="text"
                  className="p-2 form-control"
                  name={"firstname"}
                  onChange={this.handleChange}
                  value={firstname}
                  placeholder={"Enter"}
                ></input>
                {scrolled_field && scrolled_field === "First Name" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 11,
                              fontWeight: "bold",
                            }}
                            className="mb-0 mt-3"
                          >
                            Please enter valid First Name
                          </p>
                        ) : null}
              </div>
            </div>
            <div className="px-2">
              <div
                style={{ height: 55 }}
                id="Last Name"
                className="search_input form-group"
              >
                <p className="mb-0 pl-2">
                  {"Last Name"}
                  <span style={{ color: "red", fontSize: 13 }}>*</span>
                </p>
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    height: 20,
                  }}
                  type="text"
                  className="p-2 form-control"
                  name={"lastname"}
                  onChange={this.handleChange}
                  value={lastname}
                  placeholder={"Enter"}
                ></input>
                {scrolled_field && scrolled_field === "Last Name" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 11,
                              fontWeight: "bold",
                            }}
                            className="mb-0 mt-3"
                          >
                            Please enter valid Last Name
                          </p>
                        ) : null}
              </div>
            </div>
            <div className="px-2">
              <div
                style={{ height: 55 }}
                id="Date of Birth"
                className="search_input form-group"
              >
                <p className="mb-0 pl-2">
                  {"Date of Birth (MM/DD/YYYY)"}
                  <span style={{ color: "red", fontSize: 13 }}>*</span>
                </p>
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    height: 20,
                  }}
                  type="date"
                  className="p-2 form-control"
                  name={"dob"}
                  max={new Date().toISOString().split('T')[0]}
                  onChange={this.handleChange}
                  value={dob}
                  placeholder={"DD/MM/YYYY"}
                ></input>
                {scrolled_field && scrolled_field === "Date of Birth" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 11,
                              fontWeight: "bold",
                            }}
                            className="mb-0 mt-3"
                          >
                            Please enter valid date
                          </p>
                        ) : null}
              </div>
            </div>
            <div className="px-2">
              <div
                style={{ height: 55 }}
                id="Email"
                className="search_input form-group"
              >
                <p className="mb-0 pl-2">
                  {"Email"}
                  <span style={{ color: "red", fontSize: 13 }}>*</span>
                </p>
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    height: 20,
                  }}
                  type="email"
                  className="p-2 form-control"
                  name={"email"}
                  onChange={this.handleChange}
                  value={email}
                  placeholder={"Enter"}
                ></input>
                {scrolled_field && scrolled_field === "Email" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 11,
                              fontWeight: "bold",
                            }}
                            className="mb-0 mt-3"
                          >
                            Please enter valid email
                          </p>
                        ) : null}
                {showEmailError ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: 11,
                      fontWeight: "bold",
                    }}
                    className="mb-0 mt-3"
                  >
                    Please enter valid email
                  </p>
                ) : null}
              </div>
            </div>
            <div className="ml-2">
                    <ReCAPTCHA
                      sitekey={Recaptcha_site_key}
                      onChange={this.onRecaptchaChange}
                      // ref={ref}
                    />
                  </div>
                  {recaptcha_response === "" ? (
                    <p className="ml-2" style={{ color: "red", fontSize: 13 }}>
                      Please fill recaptcha
                    </p>
                  ) : null}
            <div className="my-2 d-flex align-items-center justify-content-center">
              <button
                onClick={() => this.onSubmit()}
                className="gn_btn btn btn-danger btn-lg"
              >
                Submit
              </button>
            </div>
                { isFound === "" ? null :
                <div style={{border: isFound === true ? "2px solid dodgerblue" : "none", borderRadius: 15}} className="my-2 p-2 d-flex align-items-center justify-content-center">
                    {isFound === true ? <h6>If the above information matches the record, you shall receive an email shortly. Thank You!</h6> : null}
                </div>
                }
                <div className="d-flex align-items-center justify-content-center">
                {
                  isLoading === false ? <h6 style={{color:'black'}}>loading ...</h6> : null
                }
                </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotController;
